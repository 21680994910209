.stepwizard-grid {
    margin: 1rem;
    display: grid;
    justify-content: stretch;
}

.stepwizard-grid h2, h1, p {
    justify-self: center;
}

.form-grid {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    justify-items: center;
}

.summary-grid {
    justify-self: center;
    margin-bottom: 2rem;
}

.button-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
}

.spinner-grid {
    justify-self: center;
}

/* Hide the non active step, so the whitespace will not be shown */
.rsw_2f {
    display: none;
}

.rsw_3G {
    display: block;
}
